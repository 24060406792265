/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./assets/fonts/OpenSans/OpenSans-Regular.css";
@import "./assets/fonts/OpenSans/OpenSans-Bold.css";
@import "./assets/fonts/OpenSansSemiBold/OpenSans-SemiBold.css";

@import '~swiper/swiper-bundle.min.css';
@import '~@ionic/angular/css/ionic-swiper';

b, i, strong, em {
  font-size: inherit
}

:host {
}

* {
  font-size: 16px
}

.color-green-dark {
  color: var(--ion-color-primary)
}

ion-item.no-padding {
  --padding-end: 0px;
  --inner-padding-end: 0px;
}

html, body, h2, h3, h4, h5, h6 {
  font-family: OpenSans, sans-serif;
  color: #4C5356;
}

h1 {
  color: var(--ion-color-primary);
  font-weight: bold;
  font-size: 24px
}

.bg-white {
  background-color: #fff;
}

ion-item {
  --padding-start: 0;
  border-bottom-color: var(--ion-color-primary);
}

.gold-divisor {
  height: 2px;
  background-color: #CDB074;
  margin: 20px 0
}

ion-label {
  //color: var(--ion-color-primary) !important;
  color: #4C5356 !important;
}

.d-flex {
  display: flex;
}

.share-button {
  background-color: var(--ion-color-primary);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ion-icon {
    color: #fff
  }
}

.ion-color-secondary {
  color: var(--ion-color-secondary) !important;
}

.form-margin-top {
  margin-top: 30px;
}

ion-toolbar {
  --background: #fff
}

a {
  text-decoration: none;
}

ion-item {
  --highlight-color-valid: var(--ion-color-primary);
}

.color-gray, ion-label i, small {
  color: #92949C
}

.color-green {
  color: #2FD36F
}

.color-black {
  color: #4C5356
}

.color-red {
  color: #EC445A
}

.color-dark {
  color: #222428
}

.color-primary {
  color: var(--ion-color-primary);
}

.footer-padding {
  padding: 15px
}

ion-button {
  --background-activated: var(--ion-color-primary) !important;
  --background-focused: var(--ion-color-primary) !important;
  --background-hover: var(--ion-color-primary) !important;
  --padding-end: 40px;
  --padding-start: 40px;
  --padding-top: 16px;
  --padding-bottom: 16px;
  --border-radius: 15px;
  text-transform: initial;

  ion-icon {
    font-size: 24px;
  }
}

ion-item {
  ion-icon {
    font-size: 24px;
  }
}

ion-button.bordered {
  --background: #fff;
  --color: var(--ion-color-primary);
  --border-color: var(--ion-color-primary);
  --background-hover: var(--ion-color-primary);
  --border-width: 2px;
  --border-style: solid;
  --color-activated: #fff;
  --padding-end: 20px;
  --padding-start: 20px;
  --padding-top: 25px;
  --padding-bottom: 25px;
  --box-shadow: none;
  font-family: 'OpenSansSemiBold', sans-serif;
}

ion-button.bordered.header-inverse {
  --background: transparent;
  --color: #fff;
  --border-color: #fff;
  --background-hover: transparent;
  --border-width: 2px;
  --border-style: solid;
  --color-activated: #fff;
  --padding-end: 35px;
  --padding-start: 35px;
  --padding-top: 20px;
  --padding-bottom: 20px;
  --box-shadow: none;
  font-family: 'OpenSansSemiBold', sans-serif;
}

ion-button.inverse {
  --background: var(--ion-color-primary);
  --color: #fff;
  --border-color: var(--ion-color-primary);
  --background-hover: var(--ion-color-primary);
  --border-width: 2px;
  --border-style: solid;
  --color-activated: #fff;
  --padding-end: 20px;
  --padding-start: 20px;
  --padding-top: 25px;
  --padding-bottom: 25px;
  --box-shadow: none;
  font-family: 'OpenSansSemiBold', sans-serif;
}

ion-button.poor {
  --background: transparent !important;
  --background-activated: transparent !important;
  --color: var(--ion-color-primary);
  --color-activated: var(--ion-color-primary);
  --box-shadow: none;
  font-family: 'OpenSansSemiBold', sans-serif;
}

ion-button.poor.no-padding {
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

ion-footer {
  background: #fff;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

/*
app-date-modal {
  height: 100%;
  display: flex;
}*/

ion-modal {
  --backdrop-opacity: .2;
}

ion-content > div, .calend, app-deadline-item, app-transaction-home > ion-content > ion-item-divider, app-transaction-home > ion-content > div {
  margin: auto;
  display: block;
}

.input-text-padding {
  padding: 0 0 10px 0;
  position: relative;
  left: 10px
}

.header-purple {
  border-bottom-right-radius: 30px;
  //background: linear-gradient(305.28deg, #9449F1 14.92%, #3F1474 92.69%);
  padding: 80px 15px 60px 15px;
  position: relative;
}

app-folder .header-purple {
  padding-top: 60px;
}

.header-purple > div {
  position: relative;
}

.header-purple:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  background-image: url("/assets/img/header-purple.png");
}

.after-header {
  margin-top: -35px
}

.inline-icon {
  margin-right: 10px;
}

.pointer {
  cursor: pointer;
}

.size-10 {
  font-size: 10px !important;
}

.size-12 {
  font-size: 12px
}

.size-13 {
  font-size: 13px
}

.size-14 {
  font-size: 14px
}

.size-16 {
  font-size: 16px
}

.size-18 {
  font-size: 18px
}

.size-20 {
  font-size: 20px
}

.size-22 {
  font-size: 22px
}

.size-24 {
  font-size: 24px
}

.size-30 {
  font-size: 30px
}

.size-32 {
  font-size: 32px
}

.size-40 {
  font-size: 40px
}

.segment-button-checked {
  font-weight: 700;
}

.list-content-padding {
  padding: 10px 0 20px 0;
}

.list-padding-top {
  padding-top: 20px;
}

.list-padding-bottom {
  padding-bottom: 20px;
}

.pt-0 {
  padding-top: 0
}

ion-item-divider {
  padding: 0;
  background: #fff;
}

.four-lines {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.reply {
  border-left: solid 1px #B8B8B8;
  padding-left: 10px
}

ion-select {
  --padding-start: 0
}

.weight-600 {
  font-family: 'OpenSansSemiBold', sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

.m-top-small {
  margin-top: 5px
}

.closed {
  color: #2FD36F
}

.canceled {
  color: #EC445A
}

.commission_disbursement_cancel, .commission_disbursement_closure {
  color: #FDC40C;
}

ion-tab-button {
  --background: #fff;
}

.deadline-padding {
  padding: 10px 20px 10px 20px;
}

.deadline-item {
  background-color: #FDC40C;
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 5px;
    background: #E2AD00;
  }

  .time {
    color: #fff
  }
}

.deadline-item.app {
  background-color: rgba(250, 250, 250, 1);

  &:before {
    background: rgba(230, 230, 230, 1);
  }

  .time {
    color: #92949C
  }
}

.quick-icon path {
  --color: #000;
  color: #000;
}

.sc-ion-searchbar-md-h {
  background: transparent;
}

.radius-10 {
  border-radius: 10px;
}

.bg-purple {
  background: rgba(29, 63, 42, 0.1);
}

.bg-red {
  background: #EC445A1A;
  --background: #EC445A1A;
}

.bg-green {
  background: #2FD36F1A;
}

.bg-yellow {
  background: #FDC40C1A;
  --background: #FDC40C1A;
}

ion-icon.selected {
  display: none;
}

.height-85 {
  --min-height: 85px;
}

.tab-selected {
  ion-icon.default {
    display: none;
  }

  ion-icon.selected {
    display: block;
  }
}

.tab-selected ion-label {
  color: var(--ion-color-primary) !important;
  font-weight: 700;
}

ion-header.fixed {
  position: fixed;
  left: 0;
  top: 0;

  ion-toolbar {
    --background: transparent;
    --ion-color-base: transparent !important;
  }

  ion-back-button {
    color: #fff
  }
}

ion-button.yellow {
  width: 100%;
  --background: #fdc40c;
  color: #fff;
}

ion-button.red {
  width: 100%;
  --background: #fd0c44;
  color: #fff;
}

cdk-virtual-scroll-viewport {
  height: calc(100% - 235px);
  width: 100%;
}

app-tab-calendar {
  cdk-virtual-scroll-viewport {
    height: 100%;
  }
}

.header-purple {
  z-index: 9999;
  pointer-events: none;
}

.day {
  margin: 5px auto 0 auto;
}

.today .day {
  background: var(--ion-color-primary);
  border-radius: 100px;
  color: white;
  font-size: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.autocomplete-drop {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background: #fff;
  border: solid 1px #c6c6c638;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 180px;
  overflow-y: auto;
}

.autocomplete-item {
  border-bottom: solid 1px #c6c6c638;

  &:last-child {
    border: none;
  }

  padding: 10px;
  font-size: 14px;
}

ion-item.empty {
  --min-height: 25px;
}

.purple-opacity {
  --background: rgba(141, 73, 236, 0.1);
}

.noti:first-child > div {
}


.settings ion-button.md {
  margin-top: 30px
}


ion-list > ion-item,
.autocomplete-item,
.quickactions ion-list > ion-item,
app-deadline-item,
.settings > ion-item,
ion-item:not(.empty) {
  cursor: pointer;
}

app-tab-general-calendar .calend {
  position: relative;
  //top: -20px
  padding-top: 30px;
}

ion-tab-bar {
  background: #fff;
}

ion-searchbar {
  input {
    border-radius: 30px !important;
  }
}

.header-purple ion-icon {
  color: white;
  font-size: 30px;
  pointer-events: all;
  cursor: pointer;
}

$desk-w: 1000px;
.header-purple .icon-outer {
  padding: 0 10px;
  position: absolute;
  top: 40px;
  left: 50%;
  width: 100%;
  max-width: $desk-w;
  transform: translateX(-50%);
}


.desk-w {
  max-width: $desk-w;
  margin: auto;
}

.pb-80 {
  padding-bottom: 80px;
}

ion-list:not(.with-last-border) > ion-item:last-child, app-generic-list-item:last-child ion-item {
  --border-color: transparent;
}

.hidden-mobile {
  display: none;
}

ion-button.header-inverse {
  pointer-events: all;
}

.header-purple {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.after-header {
  margin-top: 140px;
}

app-folder .after-header {
  margin-top: 120px;
}

.calendar-datetime {
  top: 200px;
}

.filters {
  flex-wrap: wrap;
}


app-step-dots {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-chip {
  padding: 5px 15px;
  color: var(--ion-color-primary);
  border: solid 1px var(--ion-color-primary);
  border-radius: 50px;
  cursor: pointer;
  background: var(--ion-color-primary-opacity);
  margin-right: 15px;
  margin-top: 15px;
  font-size: 13px;

  &.selected {
    background: var(--ion-color-primary);
    color: #fff
  }
}

@media all and (max-width: 599px) {
  .w-100-mobile {
    width: 100% !important;
  }
  app-step-dots {
    width: 100%;
    order: 1;
    flex: auto;
    padding: 10px 0;

    > div {
      width: 100% !important;
    }
  }

  ion-footer {
    > div {
      flex-wrap: wrap;

      > ion-button, > div {
        order: 2
      }

      > app-post-button {
        order: 3
      }
    }
  }

}

@media all and (min-width: 600px) {


  ion-router-outlet {

    app-tab-calendar {
      .calendar-datetime {
        top: 350px
      }
    }

    app-calendar-view {
      ion-list {
        min-height: 400px;
      }
    }

    .after-header {
      margin-top: 170px;
    }

    ion-footer > div {
      max-width: $desk-w;
      margin: auto;
    }

    ion-toolbar {
      max-width: $desk-w;
      width: 100%;
      margin: auto;
    }

    .desk-buttons {
      display: flex;
      flex-wrap: wrap;
    }

    .sett-buttons {
      width: 100%;
    }

    .desk-buttons > div {
      width: 50%;

      &:last-child {
        width: 100%;
        margin: 0
      }
    }

    ion-header {
      background: #fff;
    }

    .mini-w {
      max-width: 700px !important;
    }

    .after-header, ion-content > div:not(.header-purple) {
      margin-bottom: 30px;
      max-width: $desk-w;
    }

    .after-header:not(.no-bg), ion-content > div:not(.header-purple):not(.no-bg), .bg {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 0 17px 0 #d3d3d3;
    }

    .hidden-desk {
      display: none !important;
    }

    .hidden-mobile {
      display: block;
    }

    .header-purple .w-add {
      align-items: center;
      justify-content: space-between;
      display: flex;
    }

    .container, ion-segment, ion-item-divider > div {
      max-width: $desk-w;
      width: 100%;
      margin: auto;
    }

    .pb-80 {
      padding-bottom: 0;
    }

    .text-center {
      text-align: center
    }

    .m-auto {
      margin: auto;
    }

    .w-50 {
      width: 50%;
    }

    .w-25 {
      width: 25%;
    }

    .w-60 {
      width: 60%;
    }

    .w-40 {
      width: 40%;
    }

    .l-50 {
      position: relative;
      left: 50%;
    }

    .l-40 {
      position: relative;
      left: 40%;
    }

    .radius {
      border-radius: 10px;
      overflow: hidden;
    }

    ion-content {
      --background: rgba(248, 248, 248, 1);
    }

    .header-purple .icon-outer {
      padding: 0;
    }

    .header-purple > div {
      max-width: $desk-w;
      margin: auto;
    }

    .desk-top {
      margin-top: 30px
    }

    ion-content > div:first-child:not(.header-purple) {
      margin-top: 30px
    }
  }

}

.main-swiper {
  margin-top: 3px;
  height: 80px;
}

@media all and (min-width: 768px) {
  .main-swiper {
    height: 130px;
  }
}

.main-swiper .swiper-slide {
  padding: 2px;
  border-radius: 5px;
  overflow: hidden;
}

.swiper-detail {
  height: 300px;
}

.main-swiper .swiper-slide-thumb-active {
  box-shadow: inset 0 0 0 2px var(--ion-color-primary);
}

.swiper-slide {
  position: relative;

  img, ion-img {
    position: relative;
  }

  img.blur, ion-img.blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(15px);
    object-fit: cover;
  }
}

.section-border {
  border-bottom: solid 2px var(--ion-color-primary);
}

.bg-gold {
  background: #CDB07433;
}

.color-gold {
  color: #CDB074;
}

ion-range {
  --knob-background: var(--ion-color-primary);
  --knob-size: 20px
}

.map-buttons {
  position: absolute;
  top: 44px;
  left: 0;
  padding: 15px;
  z-index: 99;
  background: transparent;

  > div {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: var(--ion-color-primary);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.map-container {
  height: 100%;
  width: 100%;
  //height: calc(100% - 44px);
  //top: 44px;
  //width: 100%;
  //position: fixed;
  //z-index: 9999;
}

app-document-view {
  width: 100%;
}


.prop-detail-icon {
  font-size: 25px;
  margin-right: 15px;

  &.bigger {
    font-size: 30px;
  }
}

@media all and (min-width: 768px) {
  .house-row-icons {
    justify-content: center !important;

    > div:nth-child(2) {
      margin: 0 30px;
    }
  }
}

@media all and (min-width: 1024px) {
  .switch-view {
    display: none;
  }
  .virtual-parent {
    width: 50%;
    margin: 0 0 0 auto;
    box-shadow: none !important;
  }
  .map-container {
    display: block !important;
    width: 50%;
    box-shadow: none !important;
  }
  .spinner-result {
    width: 50%;
    margin: 0 0 0 auto;
    box-shadow: none !important;
    height: 100%;
  }
}

.graph {
  width: 100%;
  height: 200px;
  overflow-x: auto;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  background: rgba(160, 139, 99, .1);
  border-radius: 20px;

  .inner-graph {
    white-space: nowrap;
  }
}

.bar-outer {
  width: 50px;
  margin-bottom: 5px
}

.bar {
  background: var(--ion-color-primary);
}

.d-inline-block {
  display: inline-block;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 13px;
  color: var(--ion-color-primary);
}

.swiper-button-next, .swiper-button-prev {
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.blocked-agent * {
  color: red !important;
}

@media all and (min-width: 768px) {
  ._1-3-mobile {
    width: 33.33%
  }
}

[appdnd] {
  transition: .3s ease;
}

.fileover {
  opacity: 0.3;
}


@media all and (min-width: 1024px) {
  [appDnd] {
    position: relative;
    padding: 10px;
  }
  [appDnd]:before {
    content: '*Drop the file here to upload.';
    //position: absolute;
    //top: 10px;
    font-size: 14px;
    color: var(--ion-color-primary);
    font-weight: bold;
    //left: 50%;
    //transform: translateX(-50%);
  }
}

/* Webfont: OpenSans-SemiBold */@font-face {
    font-family: 'OpenSansSemiBold';
    src: url('OpenSans-SemiBold.eot'); /* IE9 Compat Modes */
    src: url('OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('OpenSans-SemiBold.woff') format('woff'), /* Modern Browsers */
         url('OpenSans-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
         url('OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

